import { P60ModulePage } from '~/lib/data-contract';
import { usePage } from '~/templates/pages';

export const useSearchBarUIState = () => {
    const { pageElements = [] } = usePage<P60ModulePage>();

    const isSearchEmphasized = pageElements[0]?.type === 'm210SearchbarModule';

    return { isSearchEmphasized };
};
