import styled from '@emotion/styled';
import { readableColorIsBlack } from 'color2k';
import { m } from 'framer-motion';
import { Text, TextShade } from '~/shared/components';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';

export const StyledNavigationItem = styled.a<{
    active?: boolean;
    menuColor?: string;
    isLightHeader?: boolean;
    textShade: TextShade;
}>(({ theme, menuColor = 'white', textShade, isLightHeader }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    margin: 'auto',
    height: '100%',
    whiteSpace: 'nowrap',
    outlineOffset: -1,
    textDecoration: 'none',
    background: 'none',
    color: theme.text.color[textShade],
    opacity: 1,
    transition: `opacity ${theme.animations.getDuration('fast02')} ${theme.animations.getEasing(
        'standard',
        'productive'
    )}`,

    [breakpoints.md]: {
        padding: theme.spaces[3],
    },
    '&:hover': {
        backgroundColor: !isLightHeader
            ? readableColorIsBlack(menuColor)
                ? theme.colors.light30
                : theme.colors.dark70
            : theme.colors.light30,
        textDecoration: 'none',
    },
    svg: {
        height: 'calc(24em/16)',
        width: 'calc(24em/16)',
        margin: '-0.5em -0.25em',
        transitionProperty: 'transform',
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionTimingFunction: theme.animations.getEasing('standard', 'productive'),
    },
    '&[aria-expanded="true"]': {
        svg: {
            transform: 'rotate(-180deg)',
        },
    },
}));

export const StyledLineContainer = styled.div(({ theme: { spaces, sizes } }) => ({
    position: 'relative',
    overflow: 'hidden',
    height: spaces[1],
    bottom: `calc(${spaces[1]} - ${sizes.borderWidth}px)`,
}));

export const StyledLineAnimation = styled(m.div)<{ active?: boolean }>({
    position: 'absolute',
    width: '100%',
});

export const StyledNavigationText = styled(Text)<{ linkColor?: string }>(
    ifProp('linkColor', ({ linkColor }) => ({
        color: linkColor,
    }))
);
