import React, { MouseEvent, ReactNode, memo } from 'react';

/**
 * Adds a listener that listens for click (and therefore also keyboard) events.
 * Fires the provided callback if the event came from an anchor (Or child of anchor). i.e. if someone clicked a link
 *
 * @example
 *
 * <ClickTracker
 *  callback={someTrackFunction}
 *  as="section"
 *  children={<>...</>}
 * >
 */
export const ClickTracker = memo(
    ({
        children,
        callback,
        as: Component,
    }: {
        children: ReactNode;
        as: keyof JSX.IntrinsicElements;
        callback: (anchor: HTMLAnchorElement) => void;
    }) => {
        const handleClick = (event: MouseEvent<Element>): void | null => {
            const path = event.nativeEvent.composedPath();
            const anchorElement = path.find(
                (element) => (element as HTMLElement | null)?.nodeName === 'A'
            ) as HTMLAnchorElement | undefined;

            if (!anchorElement) {
                return null;
            }

            callback(anchorElement);
        };

        return (
            <Component onClick={(event: MouseEvent<Element>) => handleClick(event)}>
                {children}
            </Component>
        );
    }
);
