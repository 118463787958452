import styled from '@emotion/styled';

export const StyledPromotedNodes = styled.div(({ theme }) => ({
    padding: `0 ${theme.gridConfig.gutter}`,
}));
export const StyledPromotedNodesList = styled.ul(({ theme }) => ({
    display: 'grid',
    padding: `${theme.spaces[2]} 0`,
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 0.5fr))',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(240px, 0.5fr)',
    overflowX: 'auto',
    gap: theme.spaces[3],
}));
