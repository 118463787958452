import styled from '@emotion/styled';
import { m } from 'framer-motion';

export const StyledNavigationIndicator = styled(m.div)(({ theme }) => ({
    position: 'absolute',
    inset: '100% 0 auto 0',
    height: 3,
    backgroundColor: theme.traits.navigationIndicator.background,
    transform: 'scaleX(0)',
    pointerEvents: 'none',
}));
