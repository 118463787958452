import { m } from 'framer-motion';
import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { StyledButton } from '$shared/components';
import { searchReset } from '~/shared/utils/styled/searchReset';
import { cover } from '~/shared/utils/styled';
import { ifProp } from 'styled-tools';

const searchButtonPadding = 2;
const searchButtonWidth = 40;

export const StyledN26SearchBar = styled.div(({ theme: { spaces, sizes } }) => ({
    padding: `${spaces[1]} 0 0 0`,
    height: sizes.N26HeightMobile,

    [breakpoints.md]: {
        maxWidth: `clamp(100px, 100%, 575px)`,
        padding: 0,
        height: '100%',
    },
}));

export const StyledSearchContainer = styled(m.div, {
    shouldForwardProp: (prop: string) => prop !== 'isFullSearchPage' && prop !== 'hasQuery',
})<{
    isFullSearchPage?: boolean;
    hasQuery?: boolean;
}>(({ theme, isFullSearchPage, hasQuery }) => ({
    alignItems: 'center',
    position: 'relative',
    left: 0,
    justifyContent: 'space-between',
    height: '100%',
    padding: !isFullSearchPage ? `0` : `0 ${theme.gridConfig.gutter}`,
    borderBottom: !isFullSearchPage || hasQuery ? 'none' : `1px solid ${theme.colors.light}`,

    '& .focus-lock-wrapper': {
        height: '100%',
    },
}));

export const StyledInput = styled.input<{ isFullSearchPage?: boolean }>(
    ({ theme: { spaces, fontSizesFluid, fontSizes, gridConfig, colors }, isFullSearchPage }) => ({
        backgroundColor: !isFullSearchPage ? colors.light : colors.dark,
        color: !isFullSearchPage ? colors.dark : colors.light,
        border: 'none',
        width: '100%',
        height: '100%',
        minWidth: spaces[7],
        fontSize: fontSizes.md,
        padding: `${spaces[2]} ${spaces[6]} ${spaces[2]} ${gridConfig.gutter}`,
        paddingLeft: searchButtonPadding * 2 + searchButtonWidth,
        paddingRight: searchButtonPadding * 2 + searchButtonWidth,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        borderRadius: !isFullSearchPage ? spaces[1] : 'none',
        ...searchReset,
        '::placeholder': {
            color: !isFullSearchPage ? colors.dark70 : colors.light50,
        },
        ':focus-visible': {
            outline: 'none',
        },
        [breakpoints.md]: {
            paddingLeft: spaces[6],
            fontSize: fontSizesFluid.md,
        },
    })
);

export const StyledSearchIcon = styled(StyledButton)<{
    isFullSearchPage?: boolean;
    iconAtRight?: boolean;
}>(
    ({ theme: { colors, spaces, gridConfig }, isFullSearchPage }) => ({
        ...cover,
        padding: 0,
        backgroundColor: !isFullSearchPage ? colors.light : colors.dark,
        width: searchButtonWidth,
        borderRadius: spaces[1],
        outlineOffset: 0,
        color: !isFullSearchPage ? colors.dark : colors.light,
        marginBottom: '2px',
        [`--edge-spacing`]: !isFullSearchPage ? '0' : gridConfig.gutter,
    }),
    ifProp(
        'iconAtRight',
        () => ({
            right: 'var(--edge-spacing)',
            left: 'initial',
        }),
        () => ({
            right: 'initial',
            left: 'var(--edge-spacing)',
        })
    )
);

export const StyledSearchBarForm = styled.form({
    height: '100%',
});
