import React from 'react';
import { BasePage } from '~/lib/data-contract';
import { Gutter, Widget } from '~/shared/components';
import { usePage } from '~/templates/pages';

type Props = {
    markup?: string;
};

export const N80Trustpilot = ({ markup }: Props) => {
    const { type = '' } = usePage<BasePage>();

    if (type === 'p100CheckoutPage') {
        return null;
    }

    return (
        <Gutter>
            <Widget markup={markup} />
        </Gutter>
    );
};
