import create from 'zustand';

import { NavigationNode } from '~/lib/data-contract';

export type MenuState = {
    node?: NavigationNode;
    parentNode?: NavigationNode;
};

type UseMobileMenu = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    activeMenuState: MenuState;
    setActiveMenuState: (menuState: MenuState) => void;
};

/**
 * Global and shared store for the mobile menu.
 * Avoid using directly - use the `useMobileMenu` hook instead
 */
export const useMobileMenuState = create<UseMobileMenu>((set) => ({
    isOpen: false,
    activeMenuState: {},
    setIsOpen: (isOpen: boolean) => set({ isOpen }),
    setActiveMenuState: (activeMenuState: MenuState) => set({ activeMenuState }),
}));
