import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledStepList = styled.ul(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[2],
    height: '100%',
    width: '100%',
    [breakpoints.md]: {
        width: 'auto',
    },
}));

export const StyledStepListItem = styled.li({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});
