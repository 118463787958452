import React, { memo } from 'react';
import { useCheckoutSteps } from '~/features/checkout';
import { Step } from './components/Step/Step';
import { StyledStepList, StyledStepListItem } from './styled';
import { useTranslation, isDevelopment } from '~/shared/utils';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { StepId } from '~/features/checkout/models/checkoutModel';
import { useBasket } from '~/features/basket';
import { useEffectOnce } from 'react-use';

export const StepNavigation = memo(() => {
    const { steps, getStepState, activeStep, goToStep } = useCheckoutSteps();
    const { translate } = useTranslation();
    const { data: basket } = useBasket();
    const { asPath } = useRouter();

    useEffect(() => {
        // Fire the identify script using an anonoymous ID known in backend
        if (window.td && basket?.anonoymousId) {
            window.td?.identify(basket?.anonoymousId);
        }
    }, [activeStep, basket?.anonoymousId]);

    // This will send users back if they refresh or
    // lands directly on a step, for development purposes
    // This is disabled locally
    useEffectOnce(() => {
        const step = asPath.split('#')[1] as StepId;
        if (!isDevelopment && step !== activeStep) {
            goToStep(steps[0]);
        }
    });

    return (
        <StyledStepList>
            <StyledStepListItem>
                <Step
                    stepId={steps[0]}
                    title={translate('checkout.steps.stepOne')}
                    isActive={getStepState(steps[0]) === 'active'}
                    isSelected={activeStep === steps[0]}
                />
            </StyledStepListItem>
            <StyledStepListItem>
                <Step
                    stepId={steps[1]}
                    title={translate('checkout.steps.stepTwo')}
                    isActive={getStepState(steps[1]) === 'active'}
                    isSelected={activeStep === steps[1]}
                />
            </StyledStepListItem>
            <StyledStepListItem>
                <Step
                    stepId={steps[2]}
                    title={translate('checkout.steps.stepThree')}
                    isActive={getStepState(steps[2]) === 'active'}
                    isSelected={activeStep === steps[2]}
                />
            </StyledStepListItem>
        </StyledStepList>
    );
});
