import { useEffect } from 'react';
import { useMedia } from 'react-use';
import { useMobileMenuState } from '~/features/navigation/components/N35MobileMegaMenu/hooks/useMobileMenuState';
import { useScrollLock } from '~/shared/hooks';
import { queries } from '~/theme/breakpoints';

const lockId = 'mobileMenu';

/**
 * Use to open and close the mobile menu.
 * It ensures a scroll lock when open.
 */
export const useMobileMenu = () => {
    const isMobile = !useMedia(queries.md, false);
    const { isOpen, activeMenuState, setIsOpen, setActiveMenuState } = useMobileMenuState();
    const { lock, unlock } = useScrollLock();

    const open = () => {
        if (isMobile && !isOpen) {
            setIsOpen(true);
            lock(lockId);
        }
    };

    const close = () => {
        if (isMobile && isOpen) {
            setIsOpen(false);
            unlock(lockId);
        }
    };

    const toggle = () => {
        if (isMobile) {
            isOpen ? close() : open();
        }
    };

    /**
     * Close in case the user breaks out of mobile
     * breakpoint - while open.
     */
    useEffect(() => {
        if (!isMobile) {
            setIsOpen(false);
            unlock(lockId);
        }
    }, [setIsOpen, unlock, isMobile]);

    return {
        isOpen,
        setIsOpen,
        activeMenuState,
        open,
        close,
        toggle,
        setActiveMenuState,
    };
};
