import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { getDuration, getEasing } from '~/theme/themes/animations/baseAnimations';

type StyledDrawerContainerProps = StyledDrawerProps;
type StyledBackdropProps = StyledDrawerProps;
type StyledContentProps = StyledDrawerProps;

type StyledDrawerProps = {
    open: boolean;
};

export const StyledDrawerContainer = styled.div<StyledDrawerContainerProps>(({ open }) => ({
    pointerEvents: open ? 'auto' : 'none',
}));

export const StyledDrawer = styled.div<{
    open?: boolean;
    offset?: number;
    isLightHeader?: boolean;
}>(
    ({ theme, offset = 0, isLightHeader }) => ({
        zIndex: theme.zIndices['drawer'],
        position: 'fixed',
        width: ['100%', '100vw'],
        top: offset, // distance to top of viewport
        bottom: 0,
        right: 0,
        left: 0,
        borderTop: theme.borders.border,
        borderTopColor: !isLightHeader ? 'unset' : theme.colors.light40,
        scrollBehavior: 'smooth',
        overflow: 'hidden auto',
        animationDuration: '150ms',
        animationTimingFunction: theme.easings.smoothEase,
        animationFillMode: 'both',
        backgroundColor: !isLightHeader ? theme.traits.background.default : theme.colors.light,
    }),
    ifProp(
        'open',
        {
            animationName: keyframes({
                from: { opacity: 0, transform: 'translate3d(200px,0,0)' },
                to: { opacity: 1, transform: 'translate3d(0,0,0)' },
            }),
        },
        {
            animationName: keyframes({
                from: { opacity: 1, transform: 'translate3d(0,0,0)' },
                to: { opacity: 0, transform: 'translate3d(200px,0,0)' },
            }),
        }
    )
);

export const StyledBackdrop = styled.div<StyledBackdropProps>(({ open, theme }) => ({
    display: open ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    zIndex: theme.zIndices[1],
}));

export const StyledContent = styled.div<StyledContentProps>(
    ({ theme }) => ({
        paddingBottom: '25vh',
        opacity: 0,
        transform: [`translate(${theme.spaces[3]}, 0)`, `translate3d(${theme.spaces[3]}, 0, 0)`],
        transitionDuration: getDuration('moderate01'),
        transitionProperty: 'transform, opacity',
        transitionTimingFunction: getEasing('exit', 'expressive'),
    }),
    ifProp('open', {
        transitionTimingFunction: getEasing('entrance', 'expressive'),
        transform: ['translate(0, 0)', 'translate3d(0, 0, 0)'],
        opacity: 1,
    })
);
