import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledHeader = styled.header(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndices['header'],
    backgroundColor: theme.traits.background.default,
}));

export const StyledHeaderContent = styled.div(({ theme }) => ({
    height: theme.sizes.headerHeightCheckout,
    display: 'grid',
    columnGap: theme.gridConfig.gutter,
    gridAutoFlow: 'column',
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridTemplateAreas: `
        "back-arrow logo ."
        "navigation navigation navigation"
    `,
    borderBottom: theme.borders.border,
    [breakpoints.md]: {
        padding: `0 ${theme.gridConfig.gutter}`,
        height: theme.sizes.N20headerHeightDesktop,
        gridTemplateAreas: `"navigation logo ."`,
    },
}));

export const StyledGridItem = styled.div<{ area?: string }>(
    ({ area }) => ({
        display: 'flex',
        gridArea: area,
        justifyContent: area === 'logo' ? 'center' : 'flex-start',
        alignItems: area === 'logo' ? 'center' : 'stretch',
    }),
    switchProp('area', {
        ['back-button']: ({ theme }) => ({
            padding: `${theme.spaces[3]} 0 0 ${theme.gridConfig.gutter}`,
        }),
    })
);
