import React from 'react';
import { StepContentContainer, StepLineContainer, StyledStepButton } from './styled';
import { StepId as StepType } from '~/features/checkout/models/checkoutModel';
import { useFrame } from '~/shared/utils';
import { Line } from '~/shared/components';
import { useTheme } from '@emotion/react';
import { RouterLink } from '~/shared/components/RouterLink';
import { useThemeShade } from '~/theme';
import { Text } from '~/shared/components';
import { useCheckoutSteps } from '~/features/checkout/hooks/useCheckoutSteps';

type Props = {
    stepId: StepType;
    title: string;
    isActive: boolean;
    isSelected: boolean;
};

export const Step = ({ stepId, title, isActive, isSelected }: Props) => {
    const { data: frame } = useFrame();
    const { animations, traits } = useTheme();
    const { textShade } = useThemeShade({ backgroundColor: traits.background.default });
    const { setActiveStep } = useCheckoutSteps();

    const checkoutpage = frame?.staticLinks?.checkoutPage?.url;

    return (
        <StepContentContainer>
            <RouterLink href={`${checkoutpage}#${stepId}`} shallow>
                <StyledStepButton
                    disabled={isActive}
                    variant="tertiary"
                    size="md"
                    showHoverIndicator={false}
                    onClick={() => setActiveStep(stepId)}
                >
                    <Text as="span" shade={textShade} variant="bodySm">
                        {title}
                    </Text>
                </StyledStepButton>
            </RouterLink>
            {isSelected && (
                <StepLineContainer
                    initial={false}
                    layoutId="line"
                    transition={{
                        type: 'spring',
                        ...animations.springDefault,
                    }}
                >
                    <Line thickness={2} />
                </StepLineContainer>
            )}
        </StepContentContainer>
    );
};
