import { useInView } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { RawHtml } from '~/shared/components';

type Props = {
    markup?: string;
};

export const Widget = ({ markup }: Props) => {
    const [hasIntersected, setHasIntersected] = useState(false);
    const ref = useRef(null);

    const isInView = useInView(ref, {
        margin: '250px 0px 250px 0px',
        once: true,
    });

    useEffect(() => {
        if (isInView) {
            setHasIntersected(true);
        }
    }, [isInView]);

    if (!markup) {
        return <></>;
    }

    return <div ref={ref}>{hasIntersected ? <RawHtml html={markup} /> : null}</div>;
};
