import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '$theme';

export const StyledLogo = styled.div<{
    mobileHeight?: string | number;
    mobileWidth?: string | number;
    height?: string | number;
    width?: string | number;
    inverted?: boolean;
}>(
    ({ theme: { colors }, mobileHeight, mobileWidth, height, width }) => ({
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',

        svg: {
            width: mobileWidth,
            height: mobileHeight,

            [breakpoints.md]: {
                width: width,
                height: height,
            },
        },
        fill: colors.dark,
        path: {
            fill: colors.dark,
        },
    }),
    ifProp('inverted', ({ theme: { colors } }) => ({
        fill: colors.light,
        path: {
            fill: colors.light,
        },
    }))
);
