import React, { memo, ReactNode, useRef, useState } from 'react';
import { StyledBackdrop, StyledDrawer, StyledDrawerContainer } from './styled';

type DrawerProps = {
    open: boolean;
    children: ReactNode;
    onClose?: () => void;
    afterClose?: () => void;
    isLightHeader?: boolean;
};

export const Drawer = memo(
    ({ children, open, onClose, afterClose, isLightHeader }: DrawerProps) => {
        const drawerContainerRef = useRef<HTMLDivElement>(null);
        const [isVisible, setIsVisible] = useState<boolean>(open);

        const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Escape') {
                onClose?.();
            }
        };

        const handleAnimationEnd = (isOpen: boolean) => {
            setIsVisible(isOpen);
            if (!isOpen) {
                afterClose?.();
            }
        };

        return (
            <StyledDrawerContainer
                open={open}
                onKeyDown={onKeyDownHandler}
                ref={drawerContainerRef}
            >
                <StyledDrawer
                    open={open}
                    offset={drawerContainerRef?.current?.getBoundingClientRect().top}
                    onAnimationEnd={() => handleAnimationEnd(open)}
                    style={{ display: open || isVisible ? '' : 'none' }}
                    isLightHeader={isLightHeader}
                >
                    {children}
                </StyledDrawer>
                <StyledBackdrop open={open} />
            </StyledDrawerContainer>
        );
    }
);
