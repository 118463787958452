import { useAnimation } from 'framer-motion';
import { useRouter } from 'next/router';
import { ComponentProps, memo, useCallback, useEffect } from 'react';
import { StyledNavigationIndicator } from './styled';

type IndicatorState = 'idle' | 'loading' | 'finishing';

export const N70NavigationIndicator = memo(
    ({ className }: ComponentProps<typeof StyledNavigationIndicator>) => {
        const router = useRouter();
        const controls = useAnimation();

        const onRouteChangeStart = useCallback(() => {
            controls.set('idle');
            controls.start('loading');
        }, [controls]);

        const onRouteChangeDone = useCallback(() => {
            controls.start('finishing');
        }, [controls]);

        useEffect(() => {
            router.events.on('routeChangeStart', onRouteChangeStart);
            router.events.on('routeChangeComplete', onRouteChangeDone);
            router.events.on('routeChangeError', onRouteChangeDone);

            return () => {
                router.events.off('routeChangeStart', onRouteChangeStart);
                router.events.off('routeChangeComplete', onRouteChangeDone);
                router.events.off('routeChangeError', onRouteChangeDone);
            };
        }, [onRouteChangeDone, onRouteChangeStart, router.events]);

        const handleAnimationComplete = useCallback(
            (completedAnimation: IndicatorState) => {
                if (completedAnimation === 'finishing') {
                    controls.start('idle');
                }
            },
            [controls]
        );

        return (
            <StyledNavigationIndicator
                className={className}
                animate={controls}
                variants={{
                    loading: {
                        scaleX: 0.9,
                        transformOrigin: '0% 0%',
                        transition: {
                            ease: [0.19, 1, 0.22, 1],
                            duration: 30,
                            transformOrigin: {
                                duration: 0,
                            },
                        },
                    },
                    finishing: {
                        scaleX: 1,
                        transformOrigin: '0% 0%',
                        transition: {
                            mass: 10,
                        },
                    },
                    idle: {
                        scaleX: 0,
                        transformOrigin: '100% 0%',
                        transition: {
                            transformOrigin: {
                                duration: 0,
                            },
                        },
                    },
                }}
                initial="idle"
                onAnimationComplete={handleAnimationComplete}
            ></StyledNavigationIndicator>
        );
    }
);
