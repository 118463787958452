import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledFooter = styled.footer(({ theme }) => ({
    // Sync with header size
    minHeight: theme.sizes.N20headerHeight,
    backgroundColor: theme.traits.background.default,
    display: 'grid',
    gap: theme.gridConfig.gap,
    columnGap: theme.gridConfig.gap,
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
        "navigation"
        "payment-icons"
    `,
    padding: `${theme.spaces[3]} ${theme.gridConfig.gutter}`,
    borderTop: theme.borders.border,
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(3, 1fr)`,
        height: theme.sizes.N20headerHeightDesktop,
        gridTemplateAreas: `"navigation . payment-icons"`,
    },
}));

export const StyledGridItem = styled.div<{ area?: string }>(({ area }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: area === 'payment-icons' ? 'flex-end' : 'flex-start',
    gridArea: area,
}));

export const StyledNavigationList = styled.ul(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[1],
    flexDirection: 'column',
    [breakpoints.md]: {
        gap: theme.spaces[7],
        flexDirection: 'row',
    },
}));
