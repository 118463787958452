import styled from '@emotion/styled';
import { StyledMenuPage } from '../MenuPage/styled';

export const StyledListItem = styled.li<{ showChildList?: boolean }>(
    ({ theme, showChildList }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        flexDirection: showChildList ? 'column' : 'row',
        [`${StyledMenuPage}`]: {
            padding: showChildList
                ? `${theme.spaces[2]} 0 ${theme.spaces[4]}`
                : `${theme.spaces[4]} ${theme.gridConfig.gutter}`,
        },
    })
);
