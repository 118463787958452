import styled from '@emotion/styled';
import { Text } from '~/shared/components/Text';

export const StyledMenuPage = styled.div(({ theme }) => ({
    height: '100%',
    padding: `${theme.spaces[4]} ${theme.gridConfig.gutter}`,
}));

export const StyledHeader = styled.div(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    justifyContent: 'space-between',
    marginBottom: theme.spaces[2],
    gap: theme.spaces[3],
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    gridTemplateRows: '25px 1fr',
    gridTemplateAreas: `
        "back-button back-button"
        "headline header-link"
    `,
}));

export const StyledMenuHeadline = styled(Text)({
    gridArea: 'headline',
});
