import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { AnimatePresence, m } from 'framer-motion';
import { NavigationNode, PromotedNavigationNode } from '~/lib/data-contract';
import { SlideDirection, slideVariants } from '~/shared/animations/slide-in-out';
import { MenuPage } from '../MenuPage';
import { lookupNodeById } from '../../utils/lookupNodeById';
import { lookupNodeByUrl } from '../../utils/lookupNodeByUrl';
import { lookupParentNode } from '../../utils/lookupParentNode';
import { useMobileMenu } from '../../hooks/useMobileMenu';
import { ClickTracker } from '~/shared/components/ClickTracker';
import { useTrackMenuClick } from '../../hooks/useTrackMenuClick';
import { PromotedNodes } from '../PromotedNodes';

const StyledWrapper = styled(ClickTracker)({
    overflow: 'hidden auto',
    flex: 1,
});

type MobileMegaMenuProps = {
    menu: NavigationNode[];
    promotedNodes?: PromotedNavigationNode[];
    activeNodeId?: string | null;
    activeNodeByUrl?: boolean;
    isLightHeader?: boolean;
};

export const MobileMegaMenu = ({
    menu,
    promotedNodes,
    activeNodeId,
    activeNodeByUrl,
    isLightHeader,
}: MobileMegaMenuProps) => {
    const { asPath } = useRouter();
    const { activeMenuState, setActiveMenuState } = useMobileMenu();
    const { track } = useTrackMenuClick();

    const [slideDirection, setSlideDirection] = useState<SlideDirection>('forwards');

    const onSelectNodeHandler = useCallback(
        (node: NavigationNode | undefined) => {
            if (node) {
                const parentNode = lookupParentNode(node, menu);
                setActiveMenuState({ node, parentNode });
                setSlideDirection('forwards');
            } else {
                // Go back to main menu
                setActiveMenuState({});
                setSlideDirection('backwards');
            }
        },
        [menu, setActiveMenuState]
    );

    const onGoBackHandler = useCallback(() => {
        onSelectNodeHandler(activeMenuState?.parentNode);
        setSlideDirection('backwards');
    }, [activeMenuState?.parentNode, onSelectNodeHandler]);

    const { node } = activeMenuState || {};

    useEffect(() => {
        if (activeNodeId === null) {
            return;
        }
        let node;

        if (activeNodeId) {
            node = lookupNodeById(activeNodeId, menu);
        } else if (activeNodeByUrl) {
            node = lookupNodeByUrl(asPath, menu);
        }
        setActiveMenuState(node ? { node, parentNode: lookupParentNode(node, menu) } : {});

        if (!activeNodeId || activeMenuState.parentNode?.id === activeNodeId) {
            onGoBackHandler();
            return;
        }
        onSelectNodeHandler(node);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNodeId, activeNodeByUrl]);

    return (
        <StyledWrapper
            as="div"
            callback={(anchor: HTMLAnchorElement) => track(anchor, activeMenuState.node)}
        >
            <AnimatePresence exitBeforeEnter initial={false} custom={{ slideDirection }}>
                <m.div
                    key={node?.id || 'main'}
                    variants={slideVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    custom={{
                        slideDirection,
                    }}
                >
                    {!node && (
                        <>
                            <MenuPage
                                nodes={menu}
                                onSelectNode={onSelectNodeHandler}
                                isLightHeader={isLightHeader}
                            />
                            <PromotedNodes nodes={promotedNodes} />
                        </>
                    )}

                    {node && (
                        <MenuPage
                            nodes={node.children || []}
                            headlineText={node.link?.text}
                            headlineLinkUrl={node.link?.url}
                            onGoBack={onGoBackHandler}
                            onSelectNode={onSelectNodeHandler}
                            showChildList
                            isLightHeader={isLightHeader}
                        />
                    )}
                </m.div>
            </AnimatePresence>
        </StyledWrapper>
    );
};
