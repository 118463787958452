import { useTheme } from '@emotion/react';
import React from 'react';
import { Link } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { useThemeShade } from '~/theme/hooks/useThemeShade';
import { StyledLink, StyledList } from './styled';

type Props = {
    menu: Link[];
    showMenu: boolean;
    isLightHeader?: boolean;
};

export const MobileMetaMenu = ({ menu, showMenu, isLightHeader }: Props) => {
    const {
        traits: { background },
    } = useTheme();
    const { textShade } = useThemeShade({ backgroundColor: background.default });

    return (
        <StyledList showMenu={showMenu}>
            {menu.map(
                (link) =>
                    link.url && (
                        <li key={weakKey(link)}>
                            <StyledLink
                                href={link.url}
                                prefetch={false}
                                title={link.title}
                                target={link.target}
                                textShade={!isLightHeader ? textShade : 'paragraph'}
                                text={link.text || link.title}
                            />
                        </li>
                    )
            )}
        </StyledList>
    );
};
