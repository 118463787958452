import ChevronRight from '$icons/chevron-right.svg';
import { useTheme } from '@emotion/react';
import React from 'react';
import { NavigationNode } from '~/lib/data-contract';
import { RouterLink } from '~/shared/components/RouterLink';
import { useThemeShade } from '~/theme';
import { StyledContent, StyledItem, StyledSuffix } from './styled';

type NodeItemProps = {
    node: NavigationNode;
    onSelectNode?: (node: NavigationNode) => void;
    type: 'default' | 'siblings' | 'listchild';
    isLightHeader?: boolean;
};

export const NodeItem = ({
    node,
    onSelectNode,
    type = 'default',
    isLightHeader,
}: NodeItemProps) => {
    const { children, link } = node;
    const asButton = !!children?.length && type == 'default';
    const {
        traits: { background },
    } = useTheme();
    const { readableColorShade } = useThemeShade({ backgroundColor: background.default });

    const onClickHandler = () => onSelectNode?.(node);

    if (asButton) {
        return (
            <StyledItem
                as="button"
                onClick={onClickHandler}
                colorShade={!isLightHeader ? readableColorShade : 'dark'}
            >
                <StyledContent type={type} children={link?.text} linkColor={node.linkColor} />
                <StyledSuffix children={<ChevronRight aria-hidden="true" />} />
            </StyledItem>
        );
    }

    return link?.url ? (
        <RouterLink href={link?.url} passHref>
            <StyledItem
                as="a"
                target={link.target}
                title={link.title}
                colorShade={!isLightHeader ? readableColorShade : 'dark'}
            >
                <StyledContent
                    type={type}
                    children={link?.text || link?.title}
                    linkColor={node.linkColor}
                />
            </StyledItem>
        </RouterLink>
    ) : null;
};
