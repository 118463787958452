import React, { ForwardedRef, useMemo } from 'react';
import { default as MainLindberghLogo } from '$icons/logo.svg';
import { default as MainTeLogo } from '$icons/te-logo.svg';
import { default as MainWaLogo } from '$icons/wa-logo.svg';
import { default as MainJdlLogo } from '$icons/jdl-logo.svg';
import { default as MainBiLogo } from '$icons/bi-logo.svg';
import { StyledLogo } from './styled';
import { LogoSizeKey, footerSizes, LogoVariant } from './sizes';
import { useFrame, useFranchise, useTranslation } from '~/shared/utils';
import { Link } from '~/shared/components/Link';
import { FRANCHISE } from '~/constants/franchise';
import { Visibility } from '$shared/utils/jsx';

export type LogoProps = {
    /**
     * Pass SVG Logo node example: <Logo children={<BasketIcon/>} description={'Go to basket'} />
     */
    children?: React.ReactNode;

    /**
     * Returns the logo in a different size
     */
    size?: LogoSizeKey;

    /**
     * Choose which logo is used. This combined with 'size' makes sure the logo has the correct sizing
     */
    variant?: LogoVariant;

    inverted?: boolean;
    mobileInverted?: boolean;
};

export const FooterLogo = React.forwardRef(
    (
        { children, size = 'default', variant, inverted, mobileInverted, ...restProps }: LogoProps,
        ref?: ForwardedRef<HTMLAnchorElement>
    ) => {
        const { data } = useFrame();
        const { translate } = useTranslation();
        const { key } = useFranchise();

        const frontPageUrl = data?.staticLinks?.frontPage?.url || '';
        const hasUrl = Boolean(frontPageUrl);

        const { FranchiseLogo, FranchiseLogoMobile, franchiseVariant } = useMemo<{
            FranchiseLogo?: JSX.Element;
            FranchiseLogoMobile?: JSX.Element;
            franchiseVariant: LogoVariant;
        }>(
            () =>
                ({
                    [FRANCHISE.TOEJEKSPERTEN]: {
                        FranchiseLogo: <MainTeLogo />,
                        FranchiseLogoMobile: <MainTeLogo />,
                        franchiseVariant: 'te' as LogoVariant,
                    },
                    [FRANCHISE.WAGNER]: {
                        FranchiseLogo: <MainWaLogo />,
                        franchiseVariant: 'wa' as LogoVariant,
                    },
                    [FRANCHISE.WAGNER_SE]: {
                        FranchiseLogo: <MainWaLogo />,
                        franchiseVariant: 'wa' as LogoVariant,
                    },
                    [FRANCHISE.LINDBERGH]: {
                        FranchiseLogo: <MainLindberghLogo />,
                        FranchiseLogoMobile: <MainLindberghLogo />,
                        franchiseVariant: 'lindbergh' as LogoVariant,
                    },
                    [FRANCHISE.JUNK_DE_LUXE]: {
                        FranchiseLogo: <MainJdlLogo />,
                        franchiseVariant: 'jdl' as LogoVariant,
                    },
                    [FRANCHISE.BISON]: {
                        FranchiseLogo: <MainBiLogo />,
                        franchiseVariant: 'bi' as LogoVariant,
                    },
                }[key]),
            [key]
        );

        const logoVariant = variant ?? franchiseVariant;

        return (
            <Link
                href={frontPageUrl}
                passHref={hasUrl}
                as={hasUrl ? 'a' : 'div'}
                aria-label={hasUrl ? translate('accessibility.backToHomepage') : undefined}
                ref={ref}
                type="router"
                {...restProps}
            >
                <>
                    <Visibility.HiddenFrom md>
                        <StyledLogo
                            mobileHeight={footerSizes[logoVariant]['mobile'].height}
                            mobileWidth={footerSizes[logoVariant]['mobile'].width}
                            inverted={mobileInverted}
                        >
                            {children ?? FranchiseLogoMobile ?? FranchiseLogo}
                        </StyledLogo>
                    </Visibility.HiddenFrom>
                    <Visibility.VisibleFrom md>
                        <StyledLogo
                            height={footerSizes[logoVariant][size].height}
                            width={footerSizes[logoVariant][size].width}
                            inverted={inverted}
                        >
                            {children ?? FranchiseLogo}
                        </StyledLogo>
                    </Visibility.VisibleFrom>
                </>
            </Link>
        );
    }
);
