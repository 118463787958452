import React, { memo, useEffect, useRef } from 'react';
import Loup from '$icons/search.svg';
import Clear from '$icons/close.svg';
import { debounce, useTranslation } from '~/shared/utils';
import {
    StyledInput,
    StyledN26SearchBar,
    StyledSearchBarForm,
    StyledSearchContainer,
    StyledSearchIcon,
} from './styled';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { useSearchHook, useSearchRouting } from '~/features/navigation';
import { useSearchBarUIState } from '../../hooks/search/useSearchBarUIState';
import { AnimatePresence } from 'framer-motion';
import { useMedia } from 'react-use';
import { queries } from '~/theme';
import { fadeScaleInOut } from './animations';
import { useSearchState } from '$features/search/hooks/useSearchState';

export type N26SearchBarNewProps = {
    isFullSearchPage?: boolean;
};

export const N26SearchBarNew = memo(({ isFullSearchPage }: N26SearchBarNewProps) => {
    const { clearSearch } = useSearchHook(350);
    const { isSearchEmphasized } = useSearchBarUIState();
    const { searchPageRouteConnector, searchQuery } = useSearchRouting();
    const { translate } = useTranslation();
    const ref = useRef<HTMLInputElement | null>(null);
    const isMobile = !useMedia(queries.md, false);
    const searchState = useSearchState();
    const animation = isMobile ? {} : fadeScaleInOut;

    const onSearchClear = (keepRoute?: boolean) => {
        if (keepRoute) {
            searchState.setQuery('');
        } else {
            if (ref?.current) {
                ref.current.value = '';
            }
            clearSearch();
            searchPageRouteConnector('');
        }
    };

    useEffect(() => {
        const searchInputRef = ref?.current;

        if (document.activeElement?.id === searchInputRef?.id) {
            return;
        }

        if (searchInputRef && searchInputRef.value !== searchQuery) {
            searchInputRef.value = searchQuery;
        }
    }, [searchQuery]);

    const doSearch = (searchTerm: string) => {
        searchPageRouteConnector(searchTerm);
    };
    const debouncedSearch = debounce(doSearch, 500);

    return (
        <AnimatePresence initial={false}>
            {!isSearchEmphasized && (
                <StyledSearchBarForm
                    onSubmit={(event) => {
                        event.preventDefault();
                        ref.current?.blur();
                    }}
                >
                    <StyledN26SearchBar>
                        <StyledSearchContainer
                            isFullSearchPage={isFullSearchPage}
                            hasQuery={!!searchQuery}
                            {...animation}
                        >
                            <StyledInput
                                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                                autoFocus
                                type="search"
                                id="headerSearchField"
                                name="headerSearchField"
                                ref={ref}
                                isFullSearchPage={isFullSearchPage}
                                placeholder={translate('header.search.placeholder')}
                                aria-label={translate('header.search.placeholder')}
                                defaultValue={searchQuery}
                                onInput={(event) => debouncedSearch(event.currentTarget.value)}
                            />

                            {!isFullSearchPage ? (
                                <StyledSearchIcon shape="icon" isFullSearchPage={isFullSearchPage}>
                                    {!searchQuery ? (
                                        <Loup aria-hidden="true" />
                                    ) : (
                                        <Clear aria-hidden="true" onClick={onSearchClear} />
                                    )}
                                    <VisuallyHidden
                                        children={
                                            ref?.current?.value.length
                                                ? translate('accessibility.search')
                                                : translate('accessibility.clear')
                                        }
                                    />
                                </StyledSearchIcon>
                            ) : (
                                <>
                                    <StyledSearchIcon
                                        shape="icon"
                                        isFullSearchPage={isFullSearchPage}
                                    >
                                        <Loup aria-hidden="true" />
                                    </StyledSearchIcon>
                                    {searchQuery && (
                                        <StyledSearchIcon
                                            shape="icon"
                                            isFullSearchPage={isFullSearchPage}
                                            iconAtRight
                                        >
                                            <Clear
                                                aria-hidden="true"
                                                onClick={() => onSearchClear(true)}
                                            />
                                        </StyledSearchIcon>
                                    )}
                                </>
                            )}
                        </StyledSearchContainer>
                    </StyledN26SearchBar>
                </StyledSearchBarForm>
            )}
        </AnimatePresence>
    );
});

N26SearchBarNew.displayName = 'N26SearchBarNew';
