import { NavigationNode } from '~/lib/data-contract';

export function lookupParentNode(
    needle: NavigationNode | undefined,
    haystack: NavigationNode[]
): NavigationNode | undefined {
    if (!needle) return needle;

    for (let i = 0; i < haystack.length; i++) {
        const node = haystack[i];
        const { children = [] } = node;

        // Check if current node is parent
        if (children?.includes(needle)) {
            return node;
        } else if (children?.length) {
            // Check if parent in children
            const parent = lookupParentNode(needle, children);

            if (parent) {
                return parent;
            }
        }
    }
}
