import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button';
import { breakpoints } from '~/theme';
import { m } from 'framer-motion';
import { center } from '~/shared/utils/styled';

export const StepContentContainer = styled.div({
    position: 'relative',
    height: '100%',
    ...center,
});

export const StepLineContainer = styled(m.div)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
});

export const StyledStepButton = styled
    .a(({ theme }) => ({
        flex: 1,
        whiteSpace: 'nowrap',
        margin: 0,
        [breakpoints.md]: {
            padding: `0 ${theme.spaces[3]}`,
        },
    }))
    .withComponent(Button);
