import { StyledResetButton } from '$shared/components/Button';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';

const StyledAnchor = StyledResetButton.withComponent('a');

export const StyledItem = styled(StyledAnchor)<{ colorShade?: keyof Theme['colors'] }>(
    ({ theme, colorShade }) => ({
        fontSize: theme.fontSizes.lg,
        fontFamily: 'inherit',
        textAlign: 'left',
        paddingTop: theme.spaces[3],
        paddingBottom: theme.spaces[3],
        width: '100%',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) auto',
        outline: 'none',
        color: colorShade ? theme.colors[colorShade] : undefined,
    })
);

export const StyledContent = styled.span<{
    truncate?: boolean;
    linkColor?: string;
    type: 'default' | 'siblings' | 'listchild';
}>(
    {
        lineHeight: 1.2,
    },
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    })),
    ifProp('linkColor', ({ linkColor }) => ({
        color: linkColor,
    })),
    switchProp('type', {
        listchild: ({ theme }) => ({
            fontSize: theme.fontSizes.md,
        }),
    })
);

export const StyledSuffix = styled.span(({ theme }) => ({
    textAlign: 'right',
    paddingLeft: theme.spaces[3],

    svg: {
        position: 'absolute',
        right: 0,
        bottom: '50%',
        transform: 'translateY(50%)',
        height: 20,
        width: 20,
    },
}));
