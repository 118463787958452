import React from 'react';
import { StyledPromotedNodes, StyledPromotedNodesList } from './styled';
import { PromotedNavigationNode } from '~/lib/data-contract';
import { Category } from '~/templates/blocks/components/Category';
import { ImageAspectRatio } from '~/templates/blocks/components/Category/styled';
import { weakKey } from '~/shared/utils/jsx';

type Props = {
    nodes?: PromotedNavigationNode[];
};

export const PromotedNodes = ({ nodes }: Props) => {
    const imageAspectRatio: ImageAspectRatio = {
        mobile: [6, 5],
        desktop: [6, 5],
    };

    return (
        <StyledPromotedNodes>
            <StyledPromotedNodesList>
                {nodes?.map((node) => (
                    <Category
                        key={weakKey(node)}
                        imageAspectRatio={imageAspectRatio}
                        callToAction={node.link}
                        image={node.image}
                        headline={node.text}
                    />
                ))}
            </StyledPromotedNodesList>
        </StyledPromotedNodes>
    );
};
