import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';

export const StyledList = styled.ul<{ showMenu: boolean }>(
    ({ theme, showMenu }) => ({
        listStyle: 'none',
        fontSize: theme.fontSizes.lg,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        opacity: 0,
        padding: `${theme.spaces[4]} ${theme.gridConfig.gutter}`,
        pointerEvents: showMenu ? 'auto' : 'none',
    }),
    ifProp('showMenu', ({ theme: { animations: { getDuration, getEasing, durations } } }) => ({
        // Fade in meta menu when the main menu has finished animating
        transition: `opacity ${getDuration('moderate01')} ${getEasing('entrance', 'expressive')} ${
            durations.fast02 + durations.moderate01
        }s`,
        opacity: 1,
    }))
);

export const StyledLink = styled(Link)(({ theme }) => ({
    display: 'block',
    padding: `${theme.spaces[3]} 0`,
}));
