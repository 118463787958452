import { useCallback } from 'react';
import { NavigationNode } from '~/lib/data-contract';
import { useGtm } from '~/shared/utils';

/**
 * @returns a method used for mapping and deploying tracking event for gtm
 */
export const useTrackMenuClick = () => {
    const { trackMenuClick } = useGtm();
    const track = useCallback(
        (clickedLink: HTMLAnchorElement, categoryNode?: NavigationNode) => {
            // Links that reside directly in the navigation, are considered firstLevel
            // Links that are inside the megamenu, are considered secondlevel
            // If clicking on firstLevel link, only send firstLevel
            // If clicking on secondLevel link, send secondLevel AND the "parent" category as firstLevel
            //
            // @example firstLevel only
            // ['Sale']
            //
            // @example firstLevel and secondLevel
            // ['Accessories', 'Socks']
            //
            // @example Accessing firstLevel, but through the open megamenu
            // ['Accessories', 'Accessories']

            const levels = [clickedLink.text];
            if (categoryNode) {
                levels.unshift(categoryNode.link?.text || '');
            }
            const [firstLevel, secondLevel] = levels;
            trackMenuClick(firstLevel, secondLevel);
        },
        [trackMenuClick]
    );
    return { track };
};
