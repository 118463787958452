import { Transition, Variant } from 'framer-motion';
import {
    bezierValues,
    durations,
    defaultTranslateX,
} from '~/theme/themes/animations/baseAnimations';

/**
 * Used for horizontal slide animations. Pass the desired config in the "custom" prop on a motion component. If nothing is passed, the variants will receive a default setting.
 */

export type SlideDirection = 'backwards' | 'forwards';
type TransitionConfig = {
    entranceTransition?: Transition;
    exitTransition?: Transition;
    translateX?: number | string;
};
type SlideAnimationConfig = {
    slideDirection?: SlideDirection;
    transitionConfig?: TransitionConfig;
};

const defaultEntranceTransition = {
    ease: bezierValues.entrance.productive,
    duration: durations.fast02,
};
const defaultExitTransition = {
    ease: bezierValues.exit.productive,
    duration: durations.fast02,
};

const slideDirections = {
    backwards: {
        initial: (config?: TransitionConfig) => {
            const { translateX, entranceTransition } = config || {};
            return {
                x: `-${translateX || defaultTranslateX}`,
                opacity: 0,
                transition: entranceTransition || defaultEntranceTransition,
            };
        },
        exit: (config?: TransitionConfig) => {
            const { translateX, exitTransition } = config || {};
            return {
                x: translateX || defaultTranslateX,
                opacity: 0,
                transition: exitTransition || defaultExitTransition,
            };
        },
    },
    forwards: {
        initial: (config?: TransitionConfig) => {
            const { translateX, entranceTransition } = config || {};
            return {
                x: translateX || defaultTranslateX,
                opacity: 0,
                transition: entranceTransition || defaultEntranceTransition,
            };
        },
        exit: (config?: TransitionConfig) => {
            const { translateX, exitTransition } = config || {};
            return {
                x: `-${translateX || defaultTranslateX}`,
                opacity: 0,
                transition: exitTransition || defaultExitTransition,
            };
        },
    },
};

/**
 * Use this in the "variants" prop of a motion component.
 */
export const slideVariants: { [key: string]: Variant } = {
    initial: (config?: SlideAnimationConfig) =>
        slideDirections[config?.slideDirection || 'forwards'].initial(config?.transitionConfig),
    exit: (config?: SlideAnimationConfig) =>
        slideDirections[config?.slideDirection || 'forwards'].exit(config?.transitionConfig),
    animate: (config?: SlideAnimationConfig) => {
        return {
            opacity: 1,
            x: 0,
            transition: config?.transitionConfig?.entranceTransition || defaultEntranceTransition,
        };
    },
};
