import React, { useMemo } from 'react';
import { StyledGridItem, StyledHeader, StyledHeaderContent } from './styled';
import { Logo } from '~/shared/components/Logo';
import { Visibility } from '~/shared/utils/jsx';
import { BackArrow } from '~/features/checkout/components/BackArrow/BackArrow';
import { StepNavigation, useCheckoutSteps } from '~/features/checkout';
import { useTheme } from '@emotion/react';
import { readableColorIsBlack } from 'color2k';
import { useRouter } from 'next/router';

export const CheckoutHeader = () => {
    const { traits } = useTheme();
    const shouldLogoBeInverted = useMemo(() => !readableColorIsBlack(traits.background.default), [
        traits.background.default,
    ]);
    const { steps, activeStep, goToPreviousStep } = useCheckoutSteps();
    const { back } = useRouter();
    const onBack = steps[0] === activeStep ? back : goToPreviousStep;

    return (
        <StyledHeader>
            <StyledHeaderContent>
                <StyledGridItem area="navigation">
                    <StepNavigation />
                </StyledGridItem>
                <Visibility.HiddenFrom md>
                    <StyledGridItem area="back-button">
                        <BackArrow onBack={onBack} />
                    </StyledGridItem>
                </Visibility.HiddenFrom>
                <StyledGridItem area="logo">
                    <Logo inverted={shouldLogoBeInverted} />
                </StyledGridItem>
            </StyledHeaderContent>
        </StyledHeader>
    );
};
