import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { PromotedNavigationNode } from '~/lib/data-contract';
import { slideVariants } from '~/shared/animations/slide-in-out';
import { Line } from '~/shared/components/Line';
import { useFrame } from '~/shared/utils';
import { bezierValues, durations } from '~/theme/themes/animations/baseAnimations';
import { Drawer } from './components/Drawer';
import { MobileMegaMenu } from './components/MobileMegaMenu';
import { MobileMetaMenu } from './components/MobileMetaMenu';
import { useMobileMenu } from './hooks/useMobileMenu';

export type N35MobileMegaMenuProps = {
    /**
     * Set the id of the active node. Overrides the activeNodeByUrl.
     * NOTE: Only checked on init
     */
    activeNodeId?: string | null;

    /**
     * Determine the active node by the location url.
     * NOTE: Only checked on init
     */
    activeNodeByUrl?: boolean;
    isLightHeader?: boolean;
};

/**
 * Mega menu for mobile. This menu renders each step as a menu page.
 */
export const N35MobileMegaMenu = ({
    activeNodeId,
    activeNodeByUrl = true,
    isLightHeader,
}: N35MobileMegaMenuProps) => {
    const { isOpen, close, activeMenuState, setActiveMenuState } = useMobileMenu();
    const { data } = useFrame();
    const { mainMenu = [], metaMenu = [] } = data?.navigation || {};
    const { events } = useRouter();
    const AnimatedBottomLine = m(Line);

    const promotedNodes = useMemo(
        () =>
            mainMenu.reduce(
                (acc: PromotedNavigationNode[], curr) =>
                    curr.promotedChildren ? [...acc, ...curr.promotedChildren] : acc,
                []
            ),
        [mainMenu]
    );

    const hasPromotedNodes = Boolean(promotedNodes.length);

    useEffect(() => {
        events.on('routeChangeStart', close);
        return () => events.off('routeChangeStart', close);
    }, [events, close]);

    return (
        <Drawer
            open={isOpen}
            onClose={() => close()}
            afterClose={() => setActiveMenuState({})}
            isLightHeader={isLightHeader}
        >
            <MobileMegaMenu
                menu={mainMenu}
                promotedNodes={promotedNodes}
                activeNodeByUrl={activeNodeByUrl}
                activeNodeId={activeNodeId}
                isLightHeader={isLightHeader}
            />
            {!activeMenuState.node && !hasPromotedNodes && (
                <AnimatedBottomLine
                    variants={slideVariants}
                    animate="animate"
                    initial="exit"
                    thickness={1}
                    custom={{
                        slideDirection: 'backwards',
                        transitionConfig: {
                            translateX: '100%',
                            entranceTransition: {
                                ease: bezierValues.entrance.expressive,
                                delay: durations.fast02,
                                duration: durations.moderate01,
                            },
                        },
                    }}
                />
            )}
            {!!metaMenu?.length && (
                <MobileMetaMenu
                    showMenu={!activeMenuState.node}
                    menu={metaMenu}
                    isLightHeader={isLightHeader}
                />
            )}
        </Drawer>
    );
};
