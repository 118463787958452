import React from 'react';
import { PaymentIcons } from '~/features/checkout';
import { usePage } from '~/templates/pages';
import { StyledFooter, StyledGridItem, StyledNavigationList } from './styled';
import { Link as LinkType, P100CheckoutPage } from '~/lib/data-contract';
import { Link } from '~/shared/components';
import { useThemeShade } from '~/theme';
import { useTheme } from '@emotion/react';

export const CheckoutFooter = () => {
    const { footerNavigation } = usePage<P100CheckoutPage>();
    const footerLinks = (footerNavigation || []) as LinkType[];
    const { traits } = useTheme();
    const { textShade } = useThemeShade({ backgroundColor: traits.background.default });

    return (
        <StyledFooter>
            <StyledGridItem area="navigation">
                {footerNavigation?.length ? (
                    <StyledNavigationList>
                        {footerLinks.map((link, index) =>
                            link.url ? (
                                <li key={index}>
                                    <Link
                                        href={link.url}
                                        text={link.text}
                                        {...link}
                                        textShade={textShade}
                                    />
                                </li>
                            ) : null
                        )}
                    </StyledNavigationList>
                ) : null}
            </StyledGridItem>
            <StyledGridItem area="payment-icons">
                <PaymentIcons />
            </StyledGridItem>
        </StyledFooter>
    );
};
