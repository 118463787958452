import styled from '@emotion/styled';

export const StyledMain = styled.main({
    flex: 1,
});

export const StyledCheckoutWrapper = styled.div({
    flex: 1,
    display: 'grid',
    // header auto, main 1fr, footer auto.
    // By using grid we also ensure children of main can use height 100%, which flex won't.
    gridTemplateRows: 'auto 1fr auto',
});
