import { getClampFullRange } from '~/theme/utils/getClampValue';

export const sizes = {
    lindbergh: {
        default: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(146, 216),
        },
        mobile: {
            height: getClampFullRange(70, 8),
            width: getClampFullRange(70, 8),
        },
        large: {
            height: getClampFullRange(26, 32),
            width: getClampFullRange(192, 234),
        },
    },
    te: {
        default: {
            height: getClampFullRange(27, 35),
            width: getClampFullRange(108, 147),
        },
        mobile: {
            height: 30,
            width: 30,
        },
        large: {
            height: getClampFullRange(50, 58),
            width: getClampFullRange(210, 254),
        },
    },
    wa: {
        default: {
            height: getClampFullRange(15, 21),
            width: getClampFullRange(108, 147),
        },
        mobile: {
            height: 30,
            width: 30,
        },
        large: {
            height: getClampFullRange(30, 36),
            width: getClampFullRange(210, 254),
        },
    },
    jdl: {
        default: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(137, 200),
        },
        mobile: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(137, 200),
        },
        large: {
            height: getClampFullRange(26, 36),
            width: getClampFullRange(178, 219),
        },
    },
    bi: {
        default: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(146, 216),
        },
        mobile: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(100, 216),
        },
        large: {
            height: getClampFullRange(26, 32),
            width: getClampFullRange(192, 234),
        },
    },
};

export const footerSizes = {
    lindbergh: {
        default: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(146, 216),
        },
        mobile: {
            height: getClampFullRange(26, 32),
            width: getClampFullRange(192, 234),
        },
        large: {
            height: getClampFullRange(26, 32),
            width: getClampFullRange(192, 234),
        },
    },
    te: {
        default: {
            height: getClampFullRange(27, 35),
            width: getClampFullRange(108, 147),
        },
        mobile: {
            height: getClampFullRange(50, 58),
            width: getClampFullRange(210, 254),
        },
        large: {
            height: getClampFullRange(50, 58),
            width: getClampFullRange(210, 254),
        },
    },
    wa: {
        default: {
            height: getClampFullRange(15, 21),
            width: getClampFullRange(108, 147),
        },
        mobile: {
            height: getClampFullRange(15, 21),
            width: getClampFullRange(108, 147),
        },
        large: {
            height: getClampFullRange(30, 36),
            width: getClampFullRange(210, 254),
        },
    },
    jdl: {
        default: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(137, 200),
        },
        mobile: {
            height: getClampFullRange(20, 29),
            width: getClampFullRange(137, 200),
        },
        large: {
            height: getClampFullRange(26, 36),
            width: getClampFullRange(178, 219),
        },
    },
    bi: {
        default: {
            height: 30,
            width: 180,
        },
        mobile: {
            height: 25,
            width: 150,
        },
        large: {
            height: 30,
            width: 180,
        },
    },
};

// Different logos needs different sizing.
// Add more variants, if e.g. Toejeksperten has a logo in a different shape,
// e.g. LogoVariant = 'lindbergh' | 'te' | 'te_alternate';
export type LogoVariant = 'lindbergh' | 'te' | 'wa' | 'jdl' | 'bi';
export type LogoSizeKey = 'default' | 'large' | 'mobile';
export type LogoSize = {
    [key in LogoSizeKey]: {
        width: string | number;
        height: string | number;
    };
};
export type Sizes = { [key in keyof typeof sizes]: LogoSize };
