import React from 'react';
import { NavigationNode } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { StyledMenuPage } from '../MenuPage/styled';
import { NodeItem } from '../NodeItem';
import { StyledListItem } from './styled';

type NodeListProps = {
    nodes: NavigationNode[];
    onSelectNode?: (node: NavigationNode) => void;
    showChildList?: boolean;
    isLightHeader?: boolean;
};
export const NodeList = ({ nodes, onSelectNode, showChildList, isLightHeader }: NodeListProps) => {
    return (
        <ul>
            {nodes?.map((node) => {
                const hasChildrenAsList = showChildList && (node.children?.length || 0) > 0;
                return (
                    <StyledListItem showChildList={hasChildrenAsList} key={weakKey(node)}>
                        <NodeItem
                            type={hasChildrenAsList ? 'siblings' : 'default'}
                            node={node}
                            onSelectNode={onSelectNode}
                            isLightHeader={isLightHeader}
                        />
                        {hasChildrenAsList ? (
                            <StyledMenuPage as="ul">
                                {node.children?.map((child) => (
                                    <StyledListItem key={weakKey(child)}>
                                        <NodeItem
                                            type="listchild"
                                            node={child}
                                            onSelectNode={onSelectNode}
                                            isLightHeader={isLightHeader}
                                        />
                                    </StyledListItem>
                                ))}
                            </StyledMenuPage>
                        ) : null}
                    </StyledListItem>
                );
            })}
        </ul>
    );
};
